<template>
	<main class="container-wide">
		<div class="flex space-between mb-40">
			<div>
				<h1 class="mb-10">Know Your Customer</h1>

				<!-- <p class="color-grey">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> -->
			</div>

			<a href="#" @click.prevent="$refs.fileInput.click()" class="btn-add"></a>

			<input ref="fileInput" class="display-none" type="file" accept="text/csv" @change="fileUploaded" />
		</div>

		<!-- CSV Overlay -->
		<VOverlay
			:visible="!!vtol.length"
			@close="
				vtol = [];
				confirmCustomers = [];
			"
		>
			<div class="mb-20">
				<h1>Vátta v-tøl</h1>

				<p class="color-grey">Vátta, at v-tølini, sum eru á skerminum, eru røtt.</p>
			</div>

			<div class="mb-20 color-grey-600" style="max-height: 320px; overflow-y: auto">
				<template v-if="!confirmCustomers.length">
					<div v-for="(vtal, index) in vtol" :key="index" class="vtal">
						{{ vtal }}

						<i class="fas fa-trash-alt hover:color-red cursor-pointer" @click="removeVtalAtIndex(index)"></i>
					</div>
				</template>

				<template v-if="confirmCustomers.length">
					<div v-for="(customer, index) in confirmCustomers" :key="index" class="vtal">
						<div :class="{ 'color-red line-through': !customer }">
							{{ customer ? customer.name : vtol[index] }}
						</div>

						<i class="fas fa-trash-alt hover:color-red cursor-pointer" @click="removeCustomerAtIndex(index)"></i>
					</div>
				</template>
			</div>

			<div class="flex space-between">
				<div @click="checkVtol" class="btn inline-block" :class="{ disabled: overlayLoading }">Kanna</div>

				<div @click="confirmVtol" class="btn inline-block" :class="{ disabled: !vtolOK || loading || overlayLoading }">Stovna</div>
			</div>
		</VOverlay>

		<template v-if="loading">
			<div class="flex justify-center">
				<i class="fas fa-spinner fa-spin fa-2x color-blue"></i>
			</div>
		</template>

		<table v-if="!!agreements.length">
			<thead>
				<th class="whitespace-nowrap">V-tal</th>

				<th class="whitespace-nowrap">Kundi</th>

				<th class="whitespace-nowrap">Stig</th>

				<th class="whitespace-nowrap">Seinast dagført</th>

				<th class="whitespace-nowrap">Bankaváttan</th>

				<th></th>
			</thead>

			<tbody>
				<tr class="relative" v-for="(agreement, index) in agreements" :key="`agreement-${index}`">
					<td>
						{{ getCustomerVTal(agreement) }}
					</td>

					<td>
						<router-link :to="{ name: 'AgreementShow', params: { id: agreement.id } }">
							{{ getCustomerName(agreement) }}
						</router-link>
					</td>

					<td>{{ getSteps(agreement)[0] }} / {{ getSteps(agreement)[1] }}</td>

					<td v-if="isApproved(agreement)">
						<router-link :to="{ name: 'AgreementShow', params: { id: agreement.id } }" class="color-green">Góðkend</router-link>
					</td>

					<td v-else-if="isSentForApproval(agreement)">
						<router-link :to="{ name: 'AgreementShow', params: { id: agreement.id } }" class="color-blue">Góðkenning umbiðin</router-link>
					</td>

					<td v-else :class="{ 'color-red': isInactive(agreement) }">
						<span>{{ getUpdatedAt(agreement) }}</span>
					</td>

					<td>-</td>

					<td class="right">
						<div class="flex justify-end">
							<div
								:title="
									agreement.agreementLink && agreement.agreementLink.numberOfVisits
										? 'Kundin hevur sæð hetta'
										: 'Kundin hevur ikki sæð hetta enn'
								"
								class="mr-10"
							>
								<i
									class="fas fa-eye"
									:class="{
										'color-blue': agreement.agreementLink && agreement.agreementLink.numberOfVisits,
										'color-grey-300': agreement.agreementLink && !agreement.agreementLink.numberOfVisits,
									}"
								></i>
							</div>

							<a v-if="getAgreementLink(agreement)" :href="getAgreementLink(agreement)" target="_blank" class="color-green">
								<i class="fas fa-link"></i>
							</a>

							<div v-if="!getAgreementLink(agreement)">-</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</main>
</template>

<script>
import VOverlay from '@/components/VOverlay.vue';
import axios from 'axios';

export default {
	name: 'KYC',

	components: { VOverlay },

	data() {
		return {
			loading: false,

			vtolOK: false,

			confirmCustomers: [],

			overlayLoading: false,

			vtol: [],

			agreements: [],
		};
	},

	async mounted() {
		await this.getAgreements();
	},

	methods: {
		getAgreementLink(agreement) {
			if (!agreement?.agreementLink?.token) {
				return '';
			}

			return `${process.env.VUE_APP_TERMINAL_CLIENT_URL}/client?token=${agreement?.agreementLink?.token}`;
		},

		getCustomerVTal(agreement) {
			return agreement?.customer?.vtal;
		},

		getCustomerName(agreement) {
			return agreement?.customer?.name;
		},

		getSteps(agreement) {
			const steps = agreement?.agreementSteps;

			if (!steps) {
				return [0, 0];
			}

			const doneSteps = steps.filter((step) => step.done);

			return [doneSteps.length, steps.length];
		},

		getUpdatedAt(agreement) {
			return new Date(agreement.updatedAt).format('%d/%m/%y %H:%M');
		},

		fileUploaded(e) {
			const reader = new FileReader();

			// read the file as text
			reader.readAsText(e.target.files[0]);

			reader.addEventListener('load', (e) => {
				const data = e.target.result;

				// split by lines
				let lines = data.split(',').map((line) => line.trim());

				// remove title
				lines = lines.slice(1);

				// filter out empty lines
				lines = lines.filter((line) => !!line);

				// unique v-tøl only
				this.vtol = [...new Set([...lines])];
			});
		},

		async getAgreements() {
			if (this.loading) {
				return;
			}

			this.loading = true;

			await axios
				.get('/agreements/kyc')
				.then((response) => {
					this.agreements = response.data;
				})
				.catch((error) => {
					console.log('Error fetching agreements in KYC', error);
				})
				.finally(() => {
					this.loading = false;
				});
		},

		async checkVtol() {
			if (this.overlayLoading) {
				return;
			}

			this.overlayLoading = true;

			await axios
				.post('/agreements/prepare-kyc-list', {
					vtol: this.vtol,
				})
				.then((response) => {
					this.confirmCustomers = response.data;

					this.vtolOK = true;
					for (const customer of response.data) {
						if (!customer) {
							this.vtolOK = false;
						}
					}
				})
				.catch((error) => {
					console.log('Error preparing customer list', error);
				})
				.finally(() => {
					this.overlayLoading = false;
				});
		},

		async confirmVtol() {
			if (this.loading) {
				return;
			}

			await axios
				.post('/agreements/bulk-create-kyc', {
					vtol: this.vtol,
				})
				.then((response) => {
					this.agreements = [...this.agreements, ...response.data];
				})
				.catch((error) => {
					console.log('Error bulk creating KYC agreements', error);
				})
				.finally(() => {
					this.overlayLoading = false;

					this.vtol = [];
					this.confirmCustomers = [];
				});
		},

		removeVtalAtIndex(index) {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			this.vtol.splice(index, 1);
		},

		removeCustomerAtIndex(index) {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			this.confirmCustomers.splice(index, 1);
			this.vtol.splice(index, 1);
		},

		isInactive(agreement) {
			if (!agreement.updatedAt) {
				return true;
			}

			const timeSince = Date.now() - new Date(agreement.updatedAt).getTime();
			return timeSince > 1000 * 60 * 60 * 48;
		},

		isApproved(agreement) {
			return agreement.approved;
		},

		isSentForApproval(agreement) {
			return agreement.sentForApproval;
		},
	},
};
</script>

<style lang="scss" scoped>
.vtal {
	position: relative;

	display: block;

	margin-bottom: 2px;
	padding: 12px 16px;

	border: 1px solid #f4f4f4;
	border-radius: 4px;

	.fa-trash-alt {
		position: absolute;

		top: 50%;
		right: 10px;
		transform: translateY(-50%);
	}

	.line-through {
		text-decoration: line-through;
	}
}
</style>
